<template>
  <div id="page-view">
    <section class="bg-light">
      <main class="privacy">
        <div class="container py-5">
          <div class="container py-5">
            <div class="privacy">
              <p class="main-title">
                <strong>Privacy Policy</strong>
              </p>
              <p class="title">Effective Date: December 24, 2024</p>
              <p>
                At Govava Inc., we prioritize your privacy and are dedicated to
                safeguarding your personal data in compliance with the General
                Data Protection Regulation (GDPR).
              </p>

              <p class="title">1. Introduction</p>
              <p>
                This Privacy Policy explains how we collect, use, and protect
                your personal information when you interact with our services.
              </p>

              <p class="title">2. Information We Collect</p>
              <p>
                We collect and process the following categories of personal
                data:
              </p>
              <ul>
                <li>
                  <strong>Contact Information:</strong> Name, email, phone
                  number, and address.
                </li>
                <li>
                  <strong>Account Details:</strong> Login credentials,
                  preferences, and settings.
                </li>
                <li>
                  <strong>Usage Data:</strong> IP address, device details, and
                  activity logs.
                </li>
                <li>
                  <strong>Voluntary Data:</strong> Any additional information
                  you provide voluntarily, such as survey responses or customer
                  support inquiries.
                </li>
              </ul>

              <p class="title">3. How We Use Your Data</p>
              <p>We process your personal data for the following purposes:</p>
              <ul>
                <li>To deliver and improve our services.</li>
                <li>To communicate updates, offers, or support.</li>
                <li>To maintain security and prevent fraud.</li>
                <li>To comply with legal obligations.</li>
              </ul>

              <p class="title">4. Our Role in Data Processing</p>
              <p>Govava Inc. acts as:</p>
              <ul>
                <li>
                  <strong>Data Controller:</strong> For customer account
                  information and marketing data.
                </li>
                <li>
                  <strong>Data Processor:</strong> When processing data on
                  behalf of our clients.
                </li>
              </ul>

              <p class="title">5. Legal Basis for Processing</p>
              <p>
                We rely on the following legal grounds for processing your
                personal data:
              </p>
              <ul>
                <li>
                  <strong>Consent:</strong> When you give explicit consent.
                </li>
                <li>
                  <strong>Contractual Necessity:</strong> To fulfill our
                  services or agreements.
                </li>
                <li>
                  <strong>Legal Compliance:</strong> To meet regulatory
                  requirements.
                </li>
                <li>
                  <strong>Legitimate Interests:</strong> To enhance our platform
                  and user experience.
                </li>
              </ul>

              <p class="title">6. Data Retention</p>
              <p>
                We retain your personal data only for as long as necessary to
                fulfill the purposes for which it was collected, or as required
                by law.
              </p>
              <p>
                You may request the deletion of your data at any time, and we
                will comply unless retention is required by legal or regulatory
                obligations.
              </p>

              <p class="title">7. Data Sharing</p>
              <p>We may share your personal data with the following parties:</p>
              <ul>
                <li>
                  <strong>Service Providers:</strong> Accountants, legal
                  advisors, cloud storage providers, etc.
                </li>
                <li>
                  <strong>Business Partners:</strong> For joint marketing or
                  collaborations.
                </li>
                <li>
                  <strong>Regulatory Authorities:</strong> When required by law
                  or regulatory obligations.
                </li>
              </ul>
              <p>
                We ensure that all data shared is done securely and with
                appropriate safeguards.
              </p>

              <p class="title">8. International Data Transfers</p>
              <p>
                If your data is transferred outside the European Economic Area
                (EEA), we implement appropriate safeguards, such as Standard
                Contractual Clauses or equivalent mechanisms, to ensure its
                protection.
              </p>

              <p class="title">9. Your GDPR Rights</p>
              <p>
                As a data subject, you have the following rights under GDPR:
              </p>
              <ul>
                <li>
                  <strong>Access:</strong> Request access to the personal data
                  we hold about you.
                </li>
                <li>
                  <strong>Correction:</strong> Correct inaccuracies in your
                  personal data.
                </li>
                <li>
                  <strong>Deletion:</strong> Request the erasure of your
                  personal data.
                </li>
                <li>
                  <strong>Restriction:</strong> Request restrictions on
                  processing your data.
                </li>
                <li>
                  <strong>Portability:</strong> Request to transfer your data to
                  another service provider.
                </li>
                <li>
                  <strong>Objection:</strong> Object to processing based on
                  legitimate interests.
                </li>
                <li>
                  <strong>Withdraw Consent:</strong> Revoke your consent at any
                  time.
                </li>
              </ul>
              <p>
                To exercise your rights, contact us at
                <strong>info@govava.com</strong>.
              </p>

              <p class="title">10. Complaints</p>
              <p>
                If you believe your rights under GDPR have been violated, you
                have the right to file a complaint with a supervisory authority,
                such as the Information Commissioner's Office (ICO).
              </p>

              <p class="title">11. Supervisory Authority Contact Details</p>
              <p>
                For users in the European Union, you may contact your national
                supervisory authority. More information is available at
                <a href="https://gdpr.eu">GDPR.eu</a> or by calling +353 57 868
                4800.
              </p>

              <p class="title">12. Security Measures</p>
              <p>
                We implement robust security measures to protect your data,
                including encryption, secure servers, and regular security
                audits.
              </p>

              <p class="title">13. Policy Updates</p>
              <p>
                We may revise this policy to reflect changes in our practices or
                regulatory requirements. Updated versions will be made available
                on our website, with the effective date clearly indicated.
              </p>

              <p class="title">14. Additional Information Required by GDPR</p>
              <p>
                To meet transparency requirements, we also include the
                following:
              </p>
              <ul>
                <li>
                  <strong>Categories of Recipients:</strong> Details of specific
                  third parties or categories (e.g., marketing partners, cloud
                  providers).
                </li>
                <li>
                  <strong>Data Sources:</strong> Clarify if data is collected
                  directly or indirectly (e.g., from third parties).
                </li>
                <li>
                  <strong>Automated Decision-Making:</strong> If applicable,
                  provide details on automated processing and its impact on
                  individuals.
                </li>
              </ul>

              <p class="title">15. Contact Information</p>
              <p>
                If you have questions about this policy or your rights, please
                contact us:
              </p>
              <ul>
                <li><strong>Email:</strong> info@govava.com</li>
                <li>
                  <strong>Address:</strong> 351 Spotswood Englishtown Road,
                  Monroe Twp, NJ 08831
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
export default {
  name: "PrivacyView",
  metaInfo: {
    title: "Privacy Policy",
    meta: [
      {
        name: "description",
        content:
          "Explore GOVAVA's comprehensive Privacy Policy, detailing how we collect, use, and safeguard your personal data. Learn about your rights and our commitment to transparency.",
      },
      {
        name: "keywords",
        content:
          "GOVAVA, Privacy Policy, personal data, data collection, user rights, data protection, cookies, usage data, service improvement, privacy practices",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      description: "",
      pagecontent: [],
    };
  },
};
</script>

<style>
.privacy {
  background: #ffffff;
}

.privacy .main-title {
  color: #2c3e50 !important;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 32px;
}

.privacy .title {
  color: #2c3e50 !important;
  font-size: 20px;
  font-weight: 500;
}
</style>
