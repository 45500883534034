<template>
  <div id="select-occasion" class="mt-2">
    <div class="container mb-3 ">
      <center>
        <h3 for=""><strong class="text-danger my-3"></strong>SELECT GENDER</h3>
        <span v-if="button" class="select-gender-error-text text-danger"
          >*Please select a Gender</span
        >
      </center>
      <label class="wrap my-5 col-md-12">
        <select name="" class="form-control noBorder" id="" v-model="gender">
          <option value="">What's the person's Gender?</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <!-- <option value="Other" >Other</option> -->
        </select>
      </label>
      <div class="mt-3 mb-3" slot="modal-footer">
        <div class="row d-flex justify-content-between">
          <div class="col-md-4 mt-3">
            <button class="btn btn-warning col-md-6" @click="prev">
              Previous
            </button>
          </div>
          <div class="col-md-4 mt-3">
            <button class="btn btn-danger float-right col-md-6" @click="next">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectGender",
  data() {
    return {
      gender: "",
      button: false,
    };
  },
  methods: {
    next() {
      if (this.gender == "") {
        this.button = true;
      } else {
        this.$emit("genderToAgeRange", this.gender);
      }
    },
    prev() {
      this.$emit("genderToAddfriend", this.gender);
    },
  },
};
</script>

<style>
.select-gender-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
