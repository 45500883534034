<template>
  <div id="select-occasion" class="mt-2">
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>WHO ARE YOU GIFTING FOR?
          </h3>
          <span v-if="button" class="select-friend-error-text text-danger"
            >*Please select or add a friend</span
          >
        </center>
        <div class="row d-flex justify-content-between my-3">
          <center>
            <button
              class="btn btn-outline float-right mx-3 mb-3"
              @click="showModal"
            >
              + Add Friend
            </button>
          </center>
          <div class="col-md-4 input-group input-group-sm">
            <div class="input-group input-group-sm" style="height: 35px;">
              <input
                class="form-control input-search"
                type="search"
                v-model="filter"
                @input="getFriends"
                placeholder="Search"
                id="filterInput"
                aria-label="Search"
              />
              <div class="input-group-append" @click="getFriends">
                <div class="btn btn-navbar input-search">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-inner">
          <div class="row my-4 friend-list">
            <div
              class="card bg-light col-md-11 my-2 mx-auto select-friend cursor-pointer"
              :class="{ select_friend_active: selected == friend.id }"
              @click="selectFriend(friend)"
              v-for="friend in friends"
            >
              <div class="my-2 card-title justify-content-between">
                <h4>
                  <i class="fa fa-user pr-3"></i>{{ friend.contact_name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 mt-3">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4 mt-3">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="addfriend-modal"
        centered
        title="Add Friend"
        hide-footer
        size="sm"
        @shown="reset"
      >
        <section class="mx-3">
          <div class="form-row">
            <div class="col-md-12 my-3">
              <input
                type="text"
                class="form-control"
                v-model="friend_name"
                placeholder="Friend Name (max 25 characters)"
                maxlength="25"
              />
              <span class="text-danger" v-if="errors.friend_name">{{
                this.errors.friend_name[0]
              }}</span>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 my-3">
              <select
                name=""
                class="form-control noBorder"
                id=""
                v-model="friend_gender"
              >
                <option value="">Friends's Gender?</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <span class="text-danger" v-if="errors.gender">{{
              this.errors.gender[0]
            }}</span>
          </div>
          <div class="form-row">
            <div class="col-md-12 my-3">
              <input
                type="text"
                class="form-control"
                v-model="mobile"
                placeholder="Mobile"
              />
            </div>
          </div>
          <div class="form-row">
            <p class="warning-paragraph text-danger">
              Please make sure the number is correct, otherwise you won't be
              able to see their Wishlist. If this is for an infant, you can skip
              it.
            </p>
          </div>
          <div class="form-row">
            <button class="btn btn-primary my-4 col-md-12" @click="addFriend()">
              ADD
            </button>
          </div>
        </section>
      </b-modal>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "AddFriend",
  props: ["menu", "gender"],
  data() {
    return {
      friends: [],
      errors: [],
      friend_name: null,
      selected_friend: null,
      button: false,
      selected: undefined,
      friend_gender: "",
      mobile: null,
      filter: null,
      loader: false,
    };
  },
  created() {
    this.getFriends();
  },
  methods: {
    reset() {
      this.friend_name = null;
      this.errors = [];
      this.mobile = null;
      this.friend_gender = "";
    },
    getFriends(selectFirst = false) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/friend/getFriends";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: user_id,
          filter: this.filter,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.friends = data.friends.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          });
          if (selectFirst) {
            this.selectFriend(data.friends[0]);
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    showModal() {
      this.$bvModal.show("addfriend-modal");
    },
    addFriend() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/friend/addFriend";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.friend_gender,
          user_id: user_id,
          friend_name: this.friend_name,
          mobile: this.mobile,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            this.errors = data.errors;
          } else {
            this.loader = false;
            this.friends = data.friends;
            this.$bvModal.hide("addfriend-modal");
            this.friend_name = null;
            this.getFriends(true);

            // this.$emit('showSignupSuccess', true);
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    selectFriend(friend) {
      this.selected = friend.id;
      this.selected_friend = friend;
    },
    next() {
      if (this.selected_friend == null) {
        this.button = true;
      } else {
        this.loader = true;
        this.$emit("checkWizardDetails", this.selected_friend);
      }
    },
    prev() {
      this.$emit("addfriendToOccasion");
    },
  },
};
</script>

<style scoped>
.select-friend-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
.warning-paragraph {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  .modal-inner {
    height: 180px;
  }

  .friend-list {
    margin: 0;
  }
}
</style>
