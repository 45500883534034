<template>
  <div id="select-occasion" class="mt-2">
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>PLEASE SELECT GROUP
            CATEGORY
          </h3>
          <span v-if="button" class="select-category-error-text text-danger"
            >*Please Select Category</span
          >
        </center>
        <label class="wrap my-5 col-md-12">
          <div class="wizard-category-container">
            <div
              v-for="category in categories"
              class="wizard-category"
              :class="selectedCategory === category.id ? 'selected' : ''"
              @click="changeCategory(category.id)"
            >
              {{ category.label }}
            </div>
            <div
              v-if="isAuthenticated"
              class="wizard-category"
              :class="selectedCategory === 'under_fifty' ? 'selected' : ''"
              @click="changeCategory('under_fifty')"
            >
              Gift Under $50
            </div>
          </div>
        </label>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="buttons col-md-4">
              <button class="btn btn-danger col-md-6 pull-right" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SelectCategory",
  props: ["categoryToOccasion"],
  data() {
    return {
      categories: [],
      selectedCategory: "",
      button: false,
      loader: true,
      isAuthenticated: false,
    };
  },
  created() {
    this.getMenuLists();
  },
  mounted() {
    const token = JSON.parse(localStorage.getItem("userData"));

    this.isAuthenticated = !!token;
  },
  methods: {
    next() {
      if (this.selectedCategory == "") {
        this.button = true;
      } else if (this.selectedCategory === "under_fifty") {
        this.categoryToOccasion("under_fifty");
      } else {
        this.categoryToOccasion(
          this.categories.find(
            (category) => category.id === this.selectedCategory
          )
        );
      }
    },
    getMenuLists() {
      var fetch_url = process.env.VUE_APP_URL + "customer/menu/menuList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.categories = data.menus;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    changeCategory(category) {
      this.selectedCategory = category;
    },
  },
};
</script>

<style scoped>
.wizard-category-container {
  max-height: 160px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
  overflow-y: auto;
}

.wizard-category-container .wizard-category {
  height: 40px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dddddd;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #aaaaaa;
  font-size: 24px;
  font-weight: 400;
  transition: all 0.3s;
}

.wizard-category-container .wizard-category.selected {
  background: #ee732c;
  color: #ffffff;
}

.select-category-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}

.buttons {
  margin-left: auto;
}

@media screen and (max-width: 640px) {
  .wizard-category-container .wizard-category {
    padding: 12px;
  }
}
</style>
