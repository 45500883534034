<template>
  <div id="select-occasion" class="mt-2">
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>SELECT AGE RANGE
          </h3>
          <span v-if="button" class="select-age-range-error-text text-danger"
            >*Please select Age range</span
          >
        </center>
        <label class="wrap my-5 col-md-12">
          <select
            name=""
            class="form-control noBorder"
            id=""
            v-model="selected_age"
          >
            <option value="">How old is this person?</option>
            <option :value="age" v-for="age in ageranges">{{
              age.age_label
            }}</option>
          </select>
        </label>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 mt-3">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4 mt-3">
              <button class="btn btn-danger col-md-6 pull-right" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "AgeRange",
  props: ["menu"],
  data() {
    return {
      ageranges: [],
      selected_age: "",
      button: false,
      start_age: null,
      end_age: null,
      loader: true,
    };
  },
  created() {
    this.getAllrange();
  },
  methods: {
    getAllrange() {
      this.loader = true;
      var fetch_url = process.env.VUE_APP_URL + "customer/agerange/getAgerange";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          type: this.menu.label,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.ageranges) {
            this.ageranges = data.ageranges;
          } else {
            this.ageranges = data.agerange.ageranges;
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      if (this.selected_age == "") {
        this.button = true;
      } else {
        this.$emit("agerangeToTrait", this.selected_age);
      }
    },
    prev() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      if (userdata_array) {
        if (this.menu.wizard_decrypt.gender == "Both") {
          this.$emit("ageRangeToGender", this.selected_age);
        } else {
          this.$emit("ageRangeToAddfriend", this.selected_age);
        }
      } else {
        this.$emit("ageRangeToOccasion");
      }
    },
  },
};
</script>
<style>
.select-age-range-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
