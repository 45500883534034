<template>
  <div id="login-view">
    <b-overlay>
      <section class="bg-light ftco-section">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid  loader-width"
            alt
          />
        </div>
        <div class="container" v-if="loader === false">
          <div class="row flex-lg-row flex-md-row">
            <div class="col-md-5">
              <div style="margin-top: 2rem">
                <div class="card-body bg-dark-gray">
                  <h2 class="text-black mb-4">Login</h2>
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="email"
                        placeholder="Email Address/User name"
                      />
                      <!-- <span class="text-danger" v-if="errors.email">{{
                        this.errors.email
                      }}</span> -->
                      <span class="text-danger" v-if="errors.email">{{
                        this.errors.email[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        v-model="password"
                        placeholder="Password"
                      />
                      <!-- <span class="text-danger" v-if="errors.password">{{
                        this.errors.password
                      }}</span> -->
                      <span class="text-danger" v-if="errors.password">{{
                        this.errors.password[0]
                      }}</span>
                    </div>
                    <div class="row ml-2">
                      <vue-recaptcha
                        sitekey="6LdOEbUcAAAAAJD8wvT14kaBQ-RSrgPZsmMXcbMr"
                        :loadRecaptchaScript="true"
                        @verify="onVerify"
                        @error="errorMethod"
                      >
                      </vue-recaptcha>
                    </div>
                    <div class="row ml-2">
                      <span class="text-danger" v-if="captchaError"
                        ><b>Please check the recaptcha box </b></span
                      >
                    </div>
                    <span class="text-danger" v-if="errors.gRecaptcha">{{
                      this.errors.gRecaptcha[0]
                    }}</span>
                    <div class="row">
                      <div class="col-8">
                        <span class="text-danger">{{ error_msg }}</span>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <!-- /.col -->
                      <div class="col-4 mt-4 mb-2">
                        <button
                          @click="login"
                          type="button"
                          class="btn btn-primary btn-block px-4"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    <p style="font-size:14px">
                      Don’t have an account?
                      <router-link to="signup"> Sign Up</router-link>
                    </p>
                    <router-link to="forgot" style="font-size:14px"
                      >Forgot Password?</router-link
                    >
                    <!-- /.col -->
                  </form>
                  <!-- <div class="row">
                    <div class="col-md-6">
                      <a href="#" class="social-button" id="facebook-connect" @click="AuthProvider('facebook')">
                        <span>Connect with Facebook</span></a>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-6">
                      <a
                        href="#"
                        class="social-button"
                        id="google-connect"
                        @click="AuthProvider('google')"
                      >
                        <span>Connect with Google</span></a
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 my-3">
                      <!-- <a href="#" class="social-button" id="google-connect" @click="AuthProvider('google')">
                        <span>Connect with Google</span></a> -->
                      <!-- <vue-apple-signin
                        color="black"
                        :border="false"
                        type="sign in"
                      ></vue-apple-signin> -->
                      <vue-apple-login
                        mode="center-align"
                        type="sign in"
                        color="black"
                        :border="true"
                        :radius="25"
                        width="100%"
                        height="52%"
                        logoSize="medium"
                        :logoPosition="0"
                        :labelPosition="0"
                        :onSuccess="onAppleSuccess"
                        className="vue-apple-login"
                      ></vue-apple-login>
                    </div>
                  </div>
                </div>
                <!-- /.login-card-body -->
              </div>
            </div>
            <div class="col-md-7">
              <img
                src="@/assets/site/images/login-bg.jpg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import VueSocialauth from "vue-social-auth";
import axios from "axios";
import VueAppleLogin from "vue-apple-login";
import VueRecaptcha from "vue-recaptcha";

Vue.use(VueAxios, axios);
Vue.use(VueAppleLogin, {
  clientId: "com.govava.govavaai",
  scope: "name email",
  redirectURI: process.env.VUE_APP_REDIRECT_URL, //process.env.VUE_APP_REDIRECT_URL,
  state: "DE",
  usePopup: true,
});
Vue.use(VueSocialauth, {
  providers: {
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: process.env.VUE_APP_REDIRECT_URL, // Your client app URL
    },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_REDIRECT_URL, // Your client URL
    },
  },
});
export default {
  name: "LoginView",
  components: { VueRecaptcha },
  metaInfo: {
    title: "Login",
    meta: [
      {
        name: "description",
        content:
          "Securely log in to your account with our user-friendly interface. Easily access your profile using email/password or social login options, including Google and Facebook. Enjoy enhanced security with Google reCAPTCHA.",
      },
      {
        name: "keywords",
        content:
          "login, user login, email login, password login, social login, Google login, Facebook login, reCAPTCHA, secure login, Vue.js login, authentication, user authentication, web application, signup, forgot password, user experience, web security",
      },
    ],
  },
  data() {
    return {
      email: null,
      password: null,
      errors: [],
      error_msg: null,
      loader: false,
      response: null,
      captchaError: false,
    };
  },

  methods: {
    AuthProvider(provider) {
      var self = this;

      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {
          console.log({
            err: err,
          });
        });
    },
    SocialLogin(provider, response) {
      this.$http
        .post(
          process.env.VUE_APP_URL + "customer/sociallogin/" + provider,
          response
        )
        .then((response) => {
          this.checkRegister(response.data);
        })
        .catch((err) => {
          console.log({
            err: err,
          });
        });
    },
    login() {
      this.errors = [];
      this.error_msg = null;
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/webLogin";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
          gRecaptcha: this.response,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            var userData = {
              user: data.customer,
              // token: data.token,
              status: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location.href = "home";
          } else if (data.status === false && data.message != null) {
            localStorage.clear();
            this.loader = false;
            this.error_msg = data.message;
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
          grecaptcha.reset();
        })
        .catch(function(err) {
          console.log("User Data", "Error : " + err.message, "error");
        });
    },
    checkRegister(details) {
      this.errors = [];
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/saveRegister";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },

        body: JSON.stringify({
          userdetail: details.user,
          provider: details.provider,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.status === true) {
            if (data.isregistered == true) {
              var userData = {
                user: data.customer,
                status: true,
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              window.location.href = "home";
            } else {
              var socialData = {
                user: details.user,
                provider: details.provider,
                status: true,
              };
              localStorage.setItem("socialData", JSON.stringify(socialData));
              window.location.href = "signup";
            }
          } else if (data.errors) {
            this.loader = false;
            this.errors = data.errors;
          }
          if (data.status === false) {
            this.$swal("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("Register Data", "Error : " + err.message, "error");
        });
    },
    onAppleSuccess(data) {
      this.errors = [];
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/web/apple_login";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },

        body: JSON.stringify({
          appleData: data,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;

          if (data.status === true) {
            if (!data.customer?.email) {
              this.$swal(
                "Error",
                "You must share your email to login with apple!",
                "error"
              );
              return;
            }

            var userData = {
              user: data.customer,
              status: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));

            setTimeout(() => {
              window.location.href = "/";
            }, 250);
          }
          if (data.status === false) {
            this.$swal("Error", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Register Data", "Error : " + err.message, "error");
        });
    },
    onVerify(response) {
      this.response = response;
    },
    errorMethod(response) {
      console.log(" captcha Error >>>>>  ", response);
    },
  },
};
</script>
