<template>
  <div id="single-view">
    <div class="breadcrumbs">
      <a
        href="#"
        title="wizard products"
        @click="goBack()"
        class="single-product-back-link"
      >
        <h3><i class="fa fa-arrow-left"></i> BACK TO PRODUCTS</h3>
      </a>
    </div>
    <div class="container" id="wishlist-product">
      <main id="maincontent" class="row page-main single-product-wrapper">
        <a id="contentarea" tabindex="-1"></a>
        <div class="columns col2-layout">
          <div class="container">
            <div class="product-info-main">
              <h2 class="product-title page-title">
                {{ sproduct.productname }}
              </h2>
              <div class="product-info-price">
                <div
                  class="price-box price-final_price"
                  data-role="priceBox"
                  data-product-id="2070"
                  data-price-box="product-id-2070"
                >
                  <span class="price"
                    >{{ sproduct.price["@currency"] }}
                    {{ sproduct.price["#text"] }}</span
                  >
                </div>
              </div>
              <div class="product attribute overview">
                <div class="value" itemprop="description">
                  <p class="description">
                    {{ description }}
                  </p>
                  <p class="platform-name">
                    {{ sproduct.run.spider_name }}
                  </p>
                  <span
                    class="toggleButton"
                    @click="toggleShowDescription()"
                    v-if="showDescriptionButton"
                    >{{ showDescription ? "Show less" : "Show more" }}</span
                  >
                </div>
              </div>
              <div class="product-add-form">
                <form
                  data-product-sku="MT-714258"
                  action=""
                  method="post"
                  id="product_addtocart_form"
                  enctype="multipart/form-data"
                  novalidate="novalidate"
                >
                  <input type="hidden" name="product" value="2070" />
                  <input
                    type="hidden"
                    name="selected_configurable_option"
                    value=""
                  />
                  <input
                    type="hidden"
                    name="related_product"
                    id="related-products-field"
                    value=""
                  />
                  <input type="hidden" name="item" value="2070" />
                  <input
                    name="form_key"
                    type="hidden"
                    value="mO6pMQzYF9fAiJc1"
                  />
                  <div class="product-options-bottom">
                    <div class="box-tocart">
                      <div class="fieldset">
                        <div class="actions">
                          <a
                            v-if="pet_name != null"
                            class="action primary tocart"
                            id="product-addtocart-button"
                            @click="saveClickstreamData()"
                          >
                            <p>Buy Now For {{ pet_name }}</p>
                          </a>
                          <a
                            v-else
                            class="action primary tocart"
                            id="product-addtocart-button"
                            @click="saveClickstreamData()"
                          >
                            <p>Buy Now</p>
                          </a>
                          <div
                            id="instant-purchase"
                            data-bind="scope:'instant-purchase'"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="product-social-links">
                <div class="product-addto-links" data-role="add-to-links">
                  <a
                    data-post=""
                    @click="savetoFavourite(sproduct)"
                    class="action towishlist btn-action link-wishlist"
                    data-action="add-to-favourite"
                    title="Add to Favorite"
                  >
                    <span>Add to Favourite</span>
                  </a>
                  <a
                    title="Add to Compare"
                    data-post=""
                    data-role="add-to-links"
                    @click="compareProduct(sproduct)"
                    class="action tocompare"
                  >
                    <span>Add to Compare</span>
                  </a>
                  <a
                    @click="selectOccasion(sproduct)"
                    class="action btn-action link-wishlist"
                    data-action="add-to-wishlist"
                    title="Add to Wishlist"
                  >
                    <img
                      style="width: 50% !important"
                      src="@/assets/site/images/wishlist.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div id="addthis_wrap">
                <div class="addthis_inline_share_toolbox"></div>
              </div>
            </div>
            <div class="product media">
              <a id="gallery-prev-area" tabindex="-1"></a>
              <div class="action-skip-wrapper">
                <a
                  class="action skip gallery-next-area"
                  href="#gallery-next-area"
                >
                  <span> Skip to the end of the images gallery </span>
                </a>
              </div>
              <div
                class="gallery-placeholder"
                data-gallery-role="gallery-placeholder"
                style=""
              >
                <div class="fotorama--hidden"></div>
                <div
                  class="fotorama-item fotorama fotorama1611137713268"
                  data-gallery-role="gallery"
                >
                  <div
                    data-gallery-role="fotorama__focusable-start"
                    tabindex="-1"
                  ></div>
                  <div
                    class="fotorama__wrap fotorama__wrap--css3 fotorama__wrap--slide fotorama__wrap--toggle-arrows fotorama__wrap--no-controls"
                    style="min-width: 0px; max-width: 100%"
                  >
                    <div
                      class="fotorama__stage"
                      data-fotorama-stage="fotorama__stage"
                      style="height: 100%; width: 100%"
                    >
                      <div
                        class="fotorama__fullscreen-icon"
                        data-gallery-role="fotorama__fullscreen-icon"
                        tabindex="0"
                        aria-label="Exit fullscreen"
                        role="button"
                      ></div>
                      <div
                        class="fotorama__arr fotorama__arr--prev"
                        tabindex="0"
                        role="button"
                        aria-label="Previous"
                        data-gallery-role="arrow"
                      >
                        <div class="fotorama__arr__arr"></div>
                      </div>
                      <div
                        class="fotorama__stage__shaft fotorama__grab"
                        tabindex="0"
                        data-gallery-role="stage-shaft"
                      >
                        <div class="fotorama__stage__frame">
                          <span v-if="sproduct.imageurl">
                            <img
                              :src="sproduct.imageurl"
                              alt=""
                              @error="() => (sproduct.imageurl = null)"
                              aria-labelledby="labelledby1611137713287"
                              class="fotorama__img magnify-opaque magnify-opaque single_product"
                              aria-hidden="false"
                            />
                          </span>
                          <span v-else>
                            <img
                              src="@/assets/site/images/noimage.jpeg"
                              alt=""
                              aria-labelledby="labelledby1611137713287"
                              class="fotorama__img magnify-opaque magnify-opaque single_product"
                              aria-hidden="false"
                            />
                          </span>

                          <div class="fotorama__caption" aria-hidden="true">
                            <div
                              class="fotorama__caption__wrap"
                              id="labelledby1611137713287"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-gallery-role="fotorama__focusable-end"
                    tabindex="-1"
                  ></div>
                </div>
              </div>
              <!--Fix for jumping content. Loader must be the same size as gallery.-->
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="conatiner mb-5 px-5 mx-5">
      <div class="col-xs-3 col-sm-3 col-md-3" v-for="coupon in coupons">
        <div class="coupon">
          <center>
            <span>
              <h4>
                {{ coupon.promotiontypes.promotiontype[0]["#text"] }}
              </h4> </span
            ><br />
          </center>

          <span class="coupon-head1 ">
            <h4 class="d-flex align-self-center">
              {{ coupon.offerdescription }}
            </h4> </span
          ><br />
          <span class=" coupon-date"
            >Start Date:{{ coupon.offerstartdate }}</span
          ><br />
          <span class=" coupon-date">End Date:{{ coupon.offerenddate }}</span
          ><br />
          <center>
            <a
              :href="coupon.clickurl"
              target="_blank"
              class="action primary tocart mt-3"
              id="product-addtocart-button"
            >
              <span> Buy Now</span>
            </a>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WishlistProduct",
  props: ["sproduct", "user_contact_id", "type"],
  metaInfo() {
    return {
      title: "Wishlist Product",
      meta: [
        {
          name: "description",
          content: this.sproduct.productname,
        },
        {
          name: "keywords",
          content: this.sproduct.name,
        },
      ],
    };
  },
  data() {
    return {
      showDescription: false,
      description: "",
      loader: false,
      errors: [],
      pages: [],
      wizarddata: [],
      user_contact_name: null,
      pet_name: null,
      coupons: [],
      showDescriptionButton: false,
    };
  },
  created() {
    this.getCouponDetails();
    let description =
      this.sproduct.description.long ?? this.sproduct.description.short;

    if (!description) return;

    if (description.split(" ").length > 40) {
      this.showDescriptionButton = true;
      this.description = "";

      description.split(" ").forEach((word, index) => {
        if (index < 40) this.description += ` ${word}`;
      });
      this.description += "...";
    } else {
      this.description = description;
    }
  },
  methods: {
    toggleShowDescription() {
      const description =
        this.sproduct.description.long ?? this.sproduct.description.short;

      if (this.description.split(" ").length === 41) {
        this.description = description;
        this.showDescription = true;
      } else {
        this.showDescription = false;
        this.description = "";

        description.split(" ").forEach((word, index) => {
          if (index < 40) this.description += ` ${word}`;
        });
        this.description += "...";
      }
    },
    getCouponDetails() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/products/getcouponDetails";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          product_id: this.sproduct.productId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.coupons = data.response.coupons;
        });
    },
    saveClickstreamData() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);

      var fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/saveAffilativeNetwork/" +
        this.sproduct.productId;
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {});

      var fetch_url =
        process.env.VUE_APP_URL + "customer/clickstream/SaveClickDatas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: this.sproduct.productId,
          clickstream_data: this.wizarddata,
          user_action: "AddToCart",
          country_code: countryCode,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", "Product Not saved !", "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
      window.open(this.sproduct.linkurl, "_blank");
    },
    savetoWishlist(sproduct) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: sproduct.productId,
          product: sproduct,
          user_id: user_id,
          wishlist_type: "User",
          clickstream_data: this.wizarddata,
          user_action: "AddToWishlist",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;

            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    compareProduct(sproduct) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/compare";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: sproduct.productId,
          product: sproduct,
          wishlist_type: "User",
          user_id: user_id,
          clickstream_data: this.wizarddata,
          user_action: "compare",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;

            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    goBack() {
      this.$emit("back");
    },
    savegift(sproduct) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var contact_name = this.user_contact_name;
      var fetch_url = process.env.VUE_APP_URL + "customer/savegift";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product: sproduct,
          user_id: user_id,
          product_id: sproduct.productId,
          user_contact_id: this.user_contact_id,
          clickstream_data: this.wizard_data,
          user_action: "SavedForGiftee",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },

    savetoFavourite(sproduct) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: sproduct.productId,
          product: sproduct,
          user_id: user_id,
          user_action: "AddToFav",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;

            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.single-product-back-link {
  display: block;
  text-align: left;
  padding-left: 20px;
  font-weight: 500;
  color: #5c5c5c !important;
}

.single-product-back-link:hover {
  text-decoration: none;
  color: #e53600 !important;
}

.product-title {
  white-space: initial;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #0b79bf;
}

.description {
  font-size: 16px;
  max-height: 190px;
  overflow: hidden;
}

.description.toggle {
  max-height: 5000px;
}

.platform-name {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.toggleButton {
  cursor: pointer;
  color: #e53600;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
}
</style>
